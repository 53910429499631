import React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from 'common/theme/AppCreative';
import { ResetCSS } from 'common/assets/css/style';
import Sticky from 'react-stickynode';
import Navbar from 'containers/AppCreative/Navbar';
import Footer from 'containers/AppCreative/Footer';
import GlobalStyle, {
    AppWrapper,
    ContentWrapper,
} from 'containers/AppCreative/appCreative.style';
import '@redq/reuse-modal/es/index.css';

import SEO from '../components/seo';

export default function index() {
    return (
        <ThemeProvider theme={theme}>
            <>
                <SEO title="GrubX – Terms & Conditions" />
                <ResetCSS />
                <GlobalStyle />

                <AppWrapper>
                    <ContentWrapper>
                        <h1>Terms & Conditions</h1>
                        <ol className="terms-list">
                            <li>
                                Individuals, aged 18 years or above can make a purchase from grubx.io. Customer will need an active email address and a telephone number which can be used for making a contact as necessary. In case if required, the customer agrees to present a positive ID issued by Canadian government (as applicable) as a proof of ownership of the credit card used for making purchase.
                            </li>
                            <li>
                                Customer takes the responsibility of maintaining the confidentiality of his/her account details including password and preventing unauthorised access to his/her account. Customer agrees to accept responsibility for all activities that occur from their account. Customer must take all necessary steps to ensure that the account credentials are kept confidential and secure and should inform the seller immediately in case customer has any reason to believe that his/her account credentials including user id and password are manipulated and/or used in an unauthorised manner.
                            </li>
                            <li>
                                Customer is solely responsible for providing the correct billing and delivery addresses.
                            </li>
                            <li>
                                Delivery will take place at the delivery address specified by customer during the ordering process. Delivery address must be within the Canada or United States.
                            </li>
                            <li>
                                When customer places an order to purchase a product/products from grubx.io, the order represents a non-binding agreement between the seller and the customer. A binding contract between seller and buyer forms only after a complete payment is fully authorised by the payment gateway and the goods are dispatched from our warehouse. Any order will be dispatched only after a successful authorisation of the billed amount in full.
                            </li>
                            <li>
                                Please note that the email confirmation is only an acknowledgement of the receipt of the order. Any product/products on the same order which we have not confirmed in the dispatch confirmation e-mail, is not a part of that binding contract.
                            </li>
                            <li>
                                Orders can only be cancelled before the goods are dispatched, by notifying the seller in writing. After dispatch, Food product/products can be returned within 7 days of purchase and 14 days in case of non-food products from the day of delivery (excluding the day of delivery). Perishable items are excluded from return.
                            </li>
                            <li>
                                Only the written conversation will be considered for any change or cancellation of order before or after dispatch of goods. Cash refund fee on order cancellation may apply.
                            </li>
                            <li>
                                All goods, items or offers shown on our catalogue are subjected to availability at any given time and may not be ready in our stock at all the time. If that is the case, we shall notify you with the date of availability by e-mail. We have the right to discontinue any items, goods or offers from the website at any time, and our only liability in such cases will be limited to refund any money paid to us related to those goods or items which we may no longer be able to supply.  We will notify you about the same, as soon as possible.
                            </li>
                            <li>
                                We put our utmost efforts to have your order delivered on the delivery date provided in your dispatch confirmation email. However, please note the delivery dates provided by us are only our best estimates and the actual delivery of your order may be later than this date. Any delays made by the delivery service is beyond our control and our liability.
                            </li>
                            <li>
                                In case a parcel is returned undelivered or refused at the time of delivery, we reserve the right to charge the full shipping charges for both back and forth sending of parcels and any other applicable fee and transaction charges. In such cases, perishable items are excluded from any claims for refund.
                            </li>
                            <li>
                                In case customer decides to cancel an order, we reserve the right to charge the applicable transaction charges.
                            </li>
                            <li>
                                Order with incomplete payment or non-payment shall be on hold until a complete payment is made. In case such orders are not paid with in seven day, they shall be automatically cancelled and the cancellation charges shall apply.
                            </li>
                            <li>
                                We can not be held responsible for the product quality such as, but not limited to, taste, texture, colour etc. of any product we sell. While we are willing to escalate any product related complaint to the manufacturer, we are not responsible for the resolution of such complaints. We can only offer a product return according to condition#7 above.
                            </li>
                            <li>
                                Order and the goods become customer’s responsibility and ownership after customer receives them.
                            </li>
                            <li>
                                We reserve the right to adjust prices, goods and special offers at our discretion.
                            </li>
                            <li>
                                We carry the right to modify or discontinue any or all products at any point of time, with or without any prior notice, temporarily or permanently. We bear no liability for any such modification, suspension or discontinuance of any product or services.
                            </li>
                            <li>
                                Deals, gift coupons and vouchers are exclusive and can not be combined and may not be exchanged against cash. Seller reserves the right to discontinue offers and deals at any time without any prior notice. Discounts can not be combined and discounted products may not be eligible for free shipping.
                            </li>
                            <li>
                                Free shipping is considered as offer and is complementary. We may decline to offer free shipping at our discretion.
                            </li>
                            <li>
                                Please note that pictures and images on grubx.io are only for illustration purpose. Actual product may look different than that is shown in the image.
                            </li>
                            <li>
                                All item prices are inclusive of sales tax as applicable based on jurisdiction, unless stated otherwise.
                            </li>
                            <li>
                                We keep the rights reserved to refuse to fulfill an order. If an order is refused for fulfillment, an email communication shall follow.
                            </li>
                            <li>
                                Our entire liability to you does not exceed the order cost you have paid.
                            </li>
                        </ol>
                    </ContentWrapper>
                    <Footer />
                </AppWrapper>
            </>
        </ThemeProvider>
    );
}
